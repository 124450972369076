import axios from "axios";

/**
 *
 * @param url {string}
 * @param body {string|FormData}
 * @returns {Promise<AxiosResponse<T> | Promise<AxiosResponse<T> | never>>}
 */
export const api_post = async (url, body) => axios.post(url, body).then(res => res).catch(err => err);

export const api_get = async url => axios.get(url).then(res => res).catch(err => err);
