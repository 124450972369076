import {UPDATE_MAP_DATA} from "../constants";

export default (state = {}, action) => {
    switch (action.type) {
        case UPDATE_MAP_DATA:
            return {
                ...state,
                data: action.data
            };
        default:
            return state
    }
}