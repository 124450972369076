import message from "antd/lib/message"
import uuid from "uuid";
import {store} from "../redux/store"
import {update_graph_data, update_settings} from "../redux/actions";
import {post_data} from "./script_connection";


export const api_post_graphs_data = req => {

    const injectVisible = obj => {
        const keys = Object.keys(obj);
        const clone = {...obj};
        keys.forEach(key => {
            const value = clone[key];
            clone[key] = {
                label: key,
                value: parseFloat(value.replace(",", ".")),
                visible: true,
                key: uuid.v4()
            }
        });
        return clone
    };
    store.dispatch(update_settings({loading: true}));
    post_data(req)
        .then(res => {
            store.dispatch(update_graph_data(injectVisible(res)));
            store.dispatch(update_settings({analysisDone: true, disableInput: true}));
            setTimeout(() => {
                store.dispatch(update_settings({isInputOpen: false, isCustomizeOpen: true, disableInput: false}));
            }, 2500);
        })
        .catch(e => message.error(JSON.stringify(e)))
        .finally(() => store.dispatch(update_settings({loading: false})))
};
