import {UPDATE_TIMELINE_DATA} from "../constants";

export default (state = {}, action) => {
    switch (action.type) {
        case UPDATE_TIMELINE_DATA:
            return {
                ...state,
                data: action.data
            };
        default:
            return state
    }
}
