import {UPDATE_SETTINGS} from "../constants";

export default (state = {}, action) => {
    switch (action.type) {
        case UPDATE_SETTINGS:
            return {
                ...state,
                ...action.data
            };
        default:
            return state
    }
}
