import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import it_IT from "../../locales/it_IT"
import en_EN from "../../locales/en_EN"

export default i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en_EN: {
                translation: en_EN
            },
            it_IT:{
                translation: it_IT
            }
        },
        lng: "en_EN",
        fallbackLng: "en_EN",
        interpolation: {
            escapeValue: false
        },
        useSuspense:false
    });
