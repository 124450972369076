import {api_post} from "../axios";
import {api_url} from "../index";

/**
 *
 * @param body {{message: string, type: number}} 1-> Charts | 2 -> Maps | 3 -> Timelines
 * @returns {Promise<never>}
 */
export const post_data = async body => {
    const api = await api_post(`${api_url}/nlpData`, body);
    return api.status === 200 ? api.data : Promise.reject("Server Error")
};
