import {api_post} from "./axios";
import {api_url} from "./index";

/**
 *
 * @param body {{message: string, indices: Array<{start:string, length: string}>}}
 * @returns {Promise<any>}
 */
export const post_correction = async body => {
    const api = await api_post(`${api_url}/corrections`, body);
    return api.status === 200 ? api.data : Promise.reject("Errore")
};