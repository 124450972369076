import {
    RESET,
    UPDATE_GRAPH_DATA,
    UPDATE_MAP_DATA,
    UPDATE_SETTINGS,
    UPDATE_TEXT,
    UPDATE_TIMELINE_DATA
} from "../constants";

export const update_timeline_data = data => ({type: UPDATE_TIMELINE_DATA, data});

export const update_graph_data = data => ({type: UPDATE_GRAPH_DATA, data});

export const update_map_data = data => ({type: UPDATE_MAP_DATA, data});

export const update_settings = data => ({type: UPDATE_SETTINGS, data});

export const update_text = data => ({type: UPDATE_TEXT, data});

export const reset_app = () => ({type: RESET,});
