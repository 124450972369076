import React from "react";
import {withTranslation} from "react-i18next";
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Icon from "antd/lib/icon";
import Input from "antd/lib/input";
import message from "antd/lib/message";
import Spin from "antd/lib/spin";
import {post_auth} from "../../api"
import logo from "../../assets/images/logo.jpg"
import styles from "./style.module.css";

class Login extends React.Component {

    state = {spinning: false};

    componentDidMount(): void {
        const token = localStorage.getItem("token");
        if (token) {
            this.setState({spinning: true});
            this.checkToken(token);
        }
    }

    checkToken = token => {
        const user = localStorage.getItem("user");
        const body = {user, token};
        post_auth(body)
            .then(res => {
                const {updateAuthorization} = this.props;
                if (res === "ok") updateAuthorization(true);
            })
            .catch(e => message.error(e))
            .finally(() => this.setState({spinning: false}))
    };

    handleSubmit = e => {
        e.preventDefault();
        const {form, updateAuthorization} = this.props;
        form.validateFields((err, values) => {
            if (!err) {
                this.setState({spinning: true});
                post_auth({...values})
                    .then(res => {
                        localStorage.setItem("user", values.user);
                        localStorage.setItem("token", res);
                        updateAuthorization(true)
                    })
                    .catch(e => message.error(e))
                    .finally(() => this.setState({spinning: false}))
            }
        });
    };

    render() {
        const {spinning} = this.state;
        const {form, t} = this.props;
        const {getFieldDecorator} = form;
        const inputUsernameProps = {
            prefix: <Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>
        };
        const inputPasswordProps = {
            prefix: <Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>,
            type: "password"
        };
        const submitButtonProps = {
            type: "primary",
            htmlType: "submit",
            ghost: true,
            block: true,
            children: `${t("login_submit")}`
        };
        const formProps = {
            onSubmit: this.handleSubmit,
            hideRequiredMark: true
        };
        return (
            <div className={styles.container}>
                <img src={logo} alt="ARIA"/>
                <Form {...formProps}>
                    <Spin spinning={spinning}>
                        <Form.Item label={t("login_username")}>
                            {getFieldDecorator("user", {
                                rules: [{required: true, message: `${t("login_username_missing")}`}],
                            })(
                                <Input {...inputUsernameProps}/>,
                            )}
                        </Form.Item>
                        <Form.Item label={t("login_password")}>
                            {getFieldDecorator("pass", {
                                rules: [{required: true, message: `${t("login_password_missing")}`}],
                            })(
                                <Input {...inputPasswordProps}/>,
                            )}
                        </Form.Item>
                        <Form.Item>
                            <Button {...submitButtonProps}/>
                        </Form.Item>
                    </Spin>
                </Form>
            </div>
        )
    }
}


export default withTranslation()(Form.create({name: "login_form"})(Login))