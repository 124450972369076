import config from "../../config";

export const UPDATE_GRAPH_DATA = "UPDATE_GRAPH_DATA";
export const UPDATE_TIMELINE_DATA = "UPDATE_TIMELINE_DATA";
export const UPDATE_MAP_DATA = "UPDATE_MAP_DATA";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const UPDATE_TEXT = "UPDATE_TEXT";
export const RESET = "RESET";

export const initialState = {
    timeline: {data: []},
    map: {},
    graph: {data: {}},
    text: "",
    extraData: {
        timeline: {data: []},
        map: {},
        graph: {data: {}},
    },
    settings: {
        locale: "en_EN",
        loaded: false,
        mode: "graphs",
        graphType: "pie",
        timelineType: "vertical",
        graphColor: config.graphColorPalette1.palette,
        legendMode: "legend",
        timelineColor: config.graphColorHSL[0],
        timelineMode: "alternate",
        timelineHorizontalMode: "up",
        timelineColorSolid: true,
        outputFontFamily: config.fontOptions[0],
        outputFontSize: config.fontSizeSmall,
        withPattern: false,
        resultBackground: "transparent",
        currentRouting: "citta/strade",
        mapMarker: "assets/images/markers/map-marker1.svg",
        map: undefined,
        withRouting: false,
        routingType: false,
        mapColor: config.graphColorHSL[0],
        loading: false,
        mapLanguage: "en_EN",
        graphLabelCorrections: {},
        isInputOpen: true,
        isCustomizeOpen: false
    }
};