import React from "react";
import Helmet from "react-helmet";
import config from "../../config";

const PageHead = props => (
    <Helmet>
        <title>ARIA</title>
        <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin/>
        <link href={config.fontURL} rel="stylesheet"/>
        <style>
            {
                `body {font-family:  -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
                "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
                ${config.fontOptions.toString()}, sans-serif }`
            }
        </style>
    </Helmet>
);

export default PageHead