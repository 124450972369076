import React from "react";
import logo from "../../assets/images/logo.jpg";
import icon from "../../assets/images/mobile-phone.svg";
import styles from "./styles.module.css";

const UseInLandscape = props => (
    <div className={styles.container}>
        <img src={logo} alt="ARIA"/>
        <img src={icon} alt="Use in landscape"/>
    </div>
);

export default UseInLandscape