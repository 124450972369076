import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {ConfigProvider} from "antd";
import it_IT from 'antd/lib/locale-provider/it_IT';
import en_US from 'antd/lib/locale-provider/en_US';
import moment from 'moment';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import PageHead from "./components/head";
import withSplashScreen from "./components/splashscreen";
import DisableInput from "./components/disableInput";
import Login from "./components/login";
import withLazyLoading from "./components/withLazyLoading";
import UseInLandscape from "./components/useInLandscape";


const LazyNavBar = withLazyLoading(() => import("./components/navbar"));
const LazyContent = withLazyLoading(() => import("./components/content"));

class App extends React.Component {

    state = {authorized: false, isLandscape: false};


    componentDidMount(): void {
        this.checkLandscape();
        window.addEventListener('resize', () => {
            this.checkLandscape()
        })

    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', () => {
        })
    }

    checkLandscape = () => {
        const w = window.innerWidth;
        const h = window.innerHeight;
        this.setState({isLandscape: w > h})
    };

    updateAuthorization = authorized => this.setState({authorized});

    render() {
        const {authorized, isLandscape} = this.state;
        const {disableInput, locale} = this.props;
        const authorizationProps = {
            updateAuthorization: this.updateAuthorization
        };
        let currentLocale = en_US;
        moment.locale("en");
        if (locale === "it_IT") {
            currentLocale = it_IT;
            moment.locale('it');
        }
        console.warn("LOCALE", locale)
        return isLandscape
            ? (
                <ConfigProvider locale={currentLocale}>
                    {
                        authorized
                            ? (
                                <>
                                    <PageHead/>
                                    <LazyNavBar/>
                                    <LazyContent key={locale}/>
                                    {disableInput && <DisableInput/>}
                                </>
                            )
                            : (<Login {...authorizationProps}/>)
                    }
                </ConfigProvider>
            )
            : (<UseInLandscape/>)
    }
}

const mapStateToProps = state => ({
    locale: state.settings.locale,
    disableInput: state.settings.disableInput,
    resultBackground: state.settings.resultBackground,
});

export default connect(mapStateToProps)(withSplashScreen(App));
