import {api_post} from "./axios";
import {api_url} from "./index";


export const post_auth = async body => {
    const api = await api_post(`${api_url}/auth`, body);
    return api.status === 200
        ? api.data
        : api.response.status === 401
            ? Promise.reject("Unauthorized")
            : Promise.reject("Server Error")
};