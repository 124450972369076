import React, {Component} from "react";

const withLazyLoading = importComponent => {
    class WithLazyLoading extends Component {

        state = {component: null};

        async componentDidMount() {
            const {default: component} = await importComponent();
            this.setState({component});
        }

        render() {
            const {component: Component} = this.state;
            return Component ? <Component {...this.props} /> : null;
        }
    }

    return WithLazyLoading;
};

export default withLazyLoading