
import uuid from "uuid";

export const H= window.H;
const data = {
    app_id : "project",
    apikey: process.env.REACT_APP_API_KEY
};
export const platform = new  H.service.Platform(data);


export const createDefaultLayer=(language)=>
{
    return platform.createDefaultLayers({lg: language});
}


export const geocoder = platform.getGeocodingService();



export const geocode= (params)=>{
    return new Promise((resolve, reject) => {
        geocoder.geocode(params, result => resolve(result), reject)
    })
};

export  const router = platform.getRoutingService();


const routerRequestParams=(waypoints)=> {
    var routeParams = {
        mode: 'fastest;car',
        routeattributes : 'waypoints,summary,shape,legs'

    };

    return {...routeParams,...waypoints}

};

export  const calculateRoute =(params)=> {
 return new Promise((resolve, reject) => {
     router.calculateRoute(
         params,
         resolve,
         reject
     )
 })
};
export  var polylineStyle ={
    lineWidth: 4,
    strokeColor: 'hsl(180, 100%, 50%)',
    lineJoin: 'bevel',
    lineDashOffset: 1,

    MAX_LINE_WIDTH: 7

}
var routeArrowsStyle = {

            lineWidth: 3,
            fillColor: 'white',
            strokeColor: 'rgba(255, 255, 255, 1)',
            lineDash: [0, 4],
            lineTailCap: 'arrow-tail',
            lineHeadCap: 'arrow-head'
    }


export const RouterResponse= (waypoints)=>{
    return new Promise((resolve, reject) => {
        var params = routerRequestParams(waypoints);
        calculateRoute(params).then(result =>{
            var route = result.response.route[0];
            var linestring = new H.geo.LineString(),
            routeShape = route.shape,
            polyline;

        routeShape.forEach(function(point) {
            var parts = point.split(',');
            linestring.pushLatLngAlt(parts[0], parts[1]);
        });

        polyline = new H.map.Polyline(linestring, {
            style: polylineStyle
        });
        var routeArrows= new H.map.Polyline(linestring, {
            style: routeArrowsStyle
        });
        resolve(polyline)


        }).catch(e =>resolve(e));
    })
};
export const routingEuclideo=(markers)=>{
    var linestring = new H.geo.LineString();
    markers.map(marker => linestring.pushPoint( marker.getGeometry()));
   var polyline = new H.map.Polyline(linestring, {
        style: polylineStyle
    });
    var routeArrows= new H.map.Polyline(linestring, {
        style: routeArrowsStyle
    });
    return polyline
};

var params={
    searchText: '',
    city: '',
    country: '',
    maxResults: 5,
    mapview: ''

};

const getUnique = (arr,comp) =>{
    const unique = arr.map(e => e[comp])
        .map((e,i,final) => final.indexOf(e)===i && i)
        .filter(e => arr[e]).map(e =>arr[e]);

    return unique

}

export const getResponse= response =>{

        if ( response===undefined || response.Response=== undefined  || response.Response.View === undefined || response.Response.View.length===0 || response.Response.View[0].Result=== undefined  )
            return [];
        var array_result = response.Response.View[0].Result.map(result =>{return{
            uuid: uuid.v4(),
            Street: result.Location.Address.Street,
            City: result.Location.Address.City,
            County: result.Location.Address.County,
            Country: result.Location.Address.Country,
            State : result.Location.Address.State,
            Label: result.Location.Address.Label,
            Discrit: result.Location.Address.Discrit,
            type: result.MatchLevel,
            MatchQuality: result.MatchQuality,
            positions:{
                lat: result.Location.DisplayPosition.Latitude,
                lng: result.Location.DisplayPosition.Longitude
            },
            mapview: result.Location.MapView.TopLeft.Latitude.toString()+','+result.Location.MapView.TopLeft.Longitude.toString()
                +';'+result.Location.MapView.BottomRight.Latitude.toString()+','+result.Location.MapView.BottomRight.Longitude.toString(),
            active: false

        }
        });
        return  array_result;



}




const firstGeocode= (data)=>{
    return new Promise((resolve,reject)=>{
        geocode({searchText: data.searchText,maxresults: 30}).then(value =>{
                var res = getResponse(value);

                if(res.length===1 && (res[0].type==="city" || res[0].type==="state" ||(res[0].type === "street" && res[0].MatchQuality.Street[0] > 0.90))) {
                    data.found=true;
                    res[0].active=true;
                    data.locations=res

                    resolve();
                }
                var filter_city=res.filter(x=> (x.type==="city" && x.MatchQuality.City===1));
                if(filter_city.length>=1)
                    data.locations=filter_city;
                else data.locations=res;

                resolve();
            }
        )
    })
}

const resolveCountry = (data,union) => {
    return new Promise((resolve, reject) => {
        var countries = [...union];
        var array_country_promise = countries.map(country => {
            params.searchText=data.searchText;
            params.country=country;
            return   geocode(params);
        });

        Promise.all(array_country_promise).then(arrayOfResponse => {
            var results=[];

           arrayOfResponse.map(response=>{getResponse(response).map(res=>results.push(res))});
            var intersection = new Set([...results].filter(x => {
                return (x.length !== 0) ? (union.has(x.Country)  ) : false;
            }));

            var res =[];

            [...intersection].map(x => {
                if(x.type==="street" &&  x.MatchQuality.Street[0] > 0.90)
                    res.push(x)
                else if(x.type!=="street")
                    res.push(x)
        });


           data.found=([...res].length===1)

            if([...res].length>=1) {
                data.locations=[...res];
                data.locations[0].active=true;
            }
            if(intersection.length === 0) {

                data.locations = results;
            }

            resolve();




        });


    })
}

const findStreet= (data,union) =>{
    return new Promise((resolve, reject) => {
       var array_promise= union.map (city=> {
            params.searchText = data.searchText;
            params.State = (city.locations[0].State!==undefined) ?city.locations[0].State : '';
            params.city = (city.locations[0].City!==undefined) ?city.locations[0].City : '';
            params.country=city.locations[0].Country;
            params.mapview=city.locations[0].mapview;

          return   geocode(params);

        })
        Promise.all(array_promise).then(arrayOfResponse =>{
            var results=[];
            arrayOfResponse.map(response=>{getResponse(response).map(res=>results.push(res))});
            results=getUnique(results,"City");
            var res=new Set();
             results.map(x => {
                 union.map(city => {
                     if ( city.locations[0].City!==undefined && x.City === city.locations[0].City)
                         res.add(x)
                 });
             });

            res=[...res].filter(x => x.Street!==undefined && x.MatchQuality.Street[0] > 0.80)

            data.found=([...res].length===1)
            if([...res].length<=union.length && [...res].length>0) {
                data.locations = [...res];
                data.locations[0].active=true;
            }
            else{
                data.locations=results.filter(x =>x.type === 'street' && x.MatchQuality.Street[0]>0.80);

            }
            resolve()
        });


    });

}

const findCountry = data =>{
    return new Promise((resolve,reject)=>{
        var state_union= new Set();
        data.map(x =>{
           if( x.found)
               state_union.add(x.locations[0].Country)

          x.locations.map(location =>{

              if(location.type==="street")
                state_union.add(location.Country);
          })
        });

        var array_promise= data.map(x => {
            if(!x.found)
               return resolveCountry(x, state_union)
        });
        Promise.all(array_promise).then(()=>{
            resolve();
        })
    });


}


const Iterativesearch= (data_state)=>{
    return new Promise((resolve, reject) => {
        var cities=[]
        data_state.filter(x => x.found  ).map(x => {
            if (x.locations[0].type !== "state")
                cities.push(x);
        });

        if(cities.length>0) {
           var array_promise_geocode = data_state.filter(x => !x.found).map(x => findStreet(x, cities))
        }
        Promise.all(array_promise_geocode).then(()=>{
            data_state.map(x => x.found=true);
            resolve();

        });
    })
}


export const resolvePosition = data =>{

    return new Promise((resolve,reject) =>{
        var data_state = data.map(location =>{return {
            searchText: location,
            found: false,
            locations: undefined,
            uuid: uuid.v1()
        }});

       var array_promise_geocode= data_state.map((data) => firstGeocode(data));
        Promise.all(array_promise_geocode).then(() =>{


                 findCountry(data_state).then(() =>{



                        Iterativesearch(data_state).then(()=>resolve(data_state))
                    });
                });
            });


};
