import message from "antd/lib/message"
import {store} from "../redux/store"
import {update_map_data, update_settings} from "../redux/actions";
import {resolvePosition} from "../components/utils/MapUtils"
import {post_data} from "./script_connection";

export const api_post_map_data = req => {
    post_data(req)
        .then(res => {
            resolvePosition(res)
                .then(value => {
                    store.dispatch(update_map_data(value));
                    store.dispatch(update_settings({analysisDone: true, disableInput: true}));
                    setTimeout(() => {
                        store.dispatch(update_settings({
                            isInputOpen: false,
                            isCustomizeOpen: true,
                            disableInput: false
                        }));
                    }, 2500);
                });
        })
        .catch(e => message.error(e))
};