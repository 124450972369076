import message from "antd/lib/message"
import {store} from "../redux/store"
import {update_settings, update_timeline_data} from "../redux/actions";
import {post_data} from "./script_connection";

export const api_post_timeline_data = req => {
    store.dispatch(update_settings({loading: true}));
    post_data(req)
        .then(res => {
            console.warn("RES", res)
            const data = Object.keys(res).map(key => ({
                date: key,
                text: res[key],
                visible: true
            }));
            store.dispatch(update_timeline_data(data));
            store.dispatch(update_settings({analysisDone: true, disableInput: true}));
            setTimeout(() => {
                store.dispatch(update_settings({isInputOpen: false, isCustomizeOpen: true, disableInput: false}));
            }, 2500);
        })
        .catch(e => message.error(e))
        .finally(() => store.dispatch(update_settings({loading: false})))
};