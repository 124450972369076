import React from "react";
import styles from "./style.module.css";
import logo from "../../assets/images/logo.jpg";

const withSplashScreen = Component => {

    class WithSplashScreen extends React.Component {

        state = {showApp: false};

        componentDidMount() {
            setTimeout(() => {
                this.setState({showApp: true})
            }, 2000)
        }

        render() {
            const {showApp} = this.state;
            return showApp
                ? (<Component {...this.props}/>)
                : (
                    <div className={`${styles.splashScreenContainer}`}>
                        <img src={logo} alt=""/>
                    </div>
                )
        }
    }

    return WithSplashScreen
};

export default withSplashScreen